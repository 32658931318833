<template>
  <div>
    <div class="form--group">
      <div class="left--col">
        <div class="d-flex align-items-center">
          <label for="search-place-field">
            <span>{{ $t('addListing.pin.title') }}</span>
            <!--            <span class="required">*</span>-->
          </label>
          <!--          <img src="@/assets/img/icons/info.svg" class="info&#45;&#45;icon" id="mapInfo" />-->
        </div>
      </div>
      <div class="right--col">
        <button @click="toggleUsePinLocation" class="btn btn-primary--light btn-block pin--btn">
          {{
            this.usePinLocation
              ? $t('addListing.removePinLocation')
              : $t('addListing.editPinLocation')
          }}
        </button>
        <search-places
          v-show="usePinLocation"
          @placeDetailFound="placeDetailFound"
          class="input--margin"
        />
      </div>
    </div>
    <div v-show="usePinLocation" style="position: relative">
      <section-loading :show="loadingMap" />
      <div class="map--container" id="propertyMap"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LodashMixin from '@/mixins/lodash';
import SectionLoading from '@/components/content-loading/section-loading';

const SearchPlaces = () => import('@/components/listing-form/sect1/map/search-places.vue');
export default {
  name: 'map-section',
  components: { SearchPlaces, SectionLoading },
  mixins: [LodashMixin],
  data() {
    return {
      icon: {
        url: '/img/map_pin.svg', // url
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(50, 50), // scaled size
        // eslint-disable-next-line no-undef
        origin: new google.maps.Point(0, 0), // origin
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(25, 50), // anchor
      },
      map: null,
      marker: null,
      detailZoom: 15,
      overallZoom: 10,
      mapCreated: false,
      allowGeocode: true,
    };
  },
  beforeCreate() {
    // this.latitude = -6.1753924;
    // this.longitude = 106.8249641;
  },
  created() {
    this.debouncedLatLongByGeocode = this.debounce(this.getLatLongByGeocode, 500);
    if (this.form === 'edit') {
      this.allowGeocode = false;
    }
  },
  async mounted() {
    // console.log('mounted map');
    // if (this.form === 'add') {
    await this.initMap();
    // } else {
    //   this.setLoading(true);
    //   // await this.$store.dispatch('listingForm/fetchBaseData');
    //   this.setLoading(false);
    //   this.$nextTick(() => {
    //     this.initMap();
    //   });
    // }
  },
  computed: {
    ...mapState({
      form: (state) => state.listingForm.form,
      loadingMap: (state) => state.listingForm.sect1.location.map.loading,
      usePinLocation: (state) => state.listingForm.sect1.location.map.usePinLocation,
      // loadPropertyDataFromList: state =>
      //   state.listingForm.sect1.location.loadPropertyDataFromList,
      // fetchingEditListingData: state => state.listingForm.fetchingEditListingData,
    }),
    usePinLocation: {
      get() {
        return this.$store.state.listingForm.sect1.location.map.usePinLocation;
      },
      set(val) {
        this.$store.commit('listingForm/sect1/location/map/SET_USE_PIN_LOCATION', val);
      },
    },
    latitude: {
      get() {
        return this.$store.state.listingForm.sect1.location.map.lat;
      },
      set(val) {
        this.$store.commit('listingForm/sect1/location/map/SET_LAT', val);
      },
    },
    longitude: {
      get() {
        return this.$store.state.listingForm.sect1.location.map.lng;
      },
      set(val) {
        this.$store.commit('listingForm/sect1/location/map/SET_LNG', val);
      },
    },
  },
  watch: {
    latitude(val, oldVal) {
      if (oldVal !== '') {
        this.changeLocationByLatLong(this.latitude, this.longitude);
      }
    },
    longitude(val, oldVal) {
      if (oldVal !== '') {
        this.changeLocationByLatLong(this.latitude, this.longitude);
      }
    },
  },
  methods: {
    toggleUsePinLocation() {
      if (this.usePinLocation) {
        this.usePinLocation = false;
        this.latitude = null;
        this.longitude = null;
      } else {
        this.latitude = -6.1753924;
        this.longitude = 106.8249641;
        this.usePinLocation = true;
      }
    },
    setLoading(value) {
      this.$store.commit('listingForm/SET_LOADING', value);
    },
    placeDetailFound() {
      this.map.setZoom(this.detailZoom);
    },
    changeLocationByLatLong(lat, lng) {
      if (this.mapCreated) {
        // eslint-disable-next-line no-undef
        const latlng = new google.maps.LatLng(lat, lng);
        this.marker.setPosition(latlng);
        this.map.panTo(latlng);
      }
    },
    getLatLongByGeocode(searchQuery) {
      // eslint-disable-next-line no-undef
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          address: searchQuery,
        },
        (results, status) => {
          // eslint-disable-next-line no-undef
          if (status === google.maps.GeocoderStatus.OK) {
            const resultJson = results[0].geometry.location.toJSON(),
              lng = resultJson.lng;
            this.latitude = resultJson.lat;
            this.longitude = lng;
            this.map.setZoom(this.overallZoom);
          }
        },
      );
    },
    getFitBound() {
      // eslint-disable-next-line no-undef
      const bound = new google.maps.LatLngBounds();
      bound.extend(this.marker.position);
      return bound;
    },
    initMarker() {
      // eslint-disable-next-line no-undef
      this.marker = new google.maps.Marker({
        position: {
          lat: this.latitude,
          lng: this.longitude,
        },
        icon: this.icon,
        map: this.map,
        draggable: true,
        // eslint-disable-next-line no-undef
        animation: google.maps.Animation.DROP,
      });
      this.marker.addListener('dragend', (event) => {
        const self = this;
        this.allowGeocode = false;
        this.latitude = event.latLng.lat();
        this.longitude = event.latLng.lng();
        // eslint-disable-next-line no-undef
        google.maps.event.addListenerOnce(this.map, 'bounds_changed', function () {
          if (this.getZoom() > self.detailZoom) {
            this.setZoom(self.detailZoom);
          }
        });
        this.map.fitBounds(this.getFitBound());
      });
    },
    async initMap() {
      let zoom = 5;
      // eslint-disable-next-line no-empty
      if (this.form === 'add') {
      } else {
        zoom = this.detailZoom;
      }
      if (this.latitude === null) {
        this.latitude = -6.1753924;
        this.longitude = 106.8249641;
      }
      const options = {
        center: { lat: this.latitude, lng: this.longitude },
        zoom: zoom,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        streetViewControl: false,
        maxZoom: 18,
      };
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('propertyMap'), options);
      this.mapCreated = true;
      this.initMarker();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/utils/variables';
.map--container {
  height: 350px;
  border-radius: 12px;
  margin-bottom: 26px;
}
.pin--btn {
  margin-bottom: 12px;
}
</style>
